import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledTooltip
} from 'reactstrap';
import {
  AvFeedback,
  AvField,
  AvForm,
  AvGroup,
  AvInput,
} from 'availity-reactstrap-validation';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Services from './services';
import { PaginationTotal } from '../../../commons/controls';
import Lib from '../../../commons/lib';

class BizonylattombLista extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalRogzit: false,
      modalNezet: false,
      modalModosit: false,
      modalTorles: false,
      modalLezaras: false,
      cegadatArray: [],
      bizonylatTipusArray: [],
      bizonylatTipusMezoInaktiv: true,
      raktarArray: [],
      raktarMezoInaktiv: true,
      bizonylatTombJson: [],
      bizonylatTombModositObj: {},
      existsRaktar: false,
      isRaktarDisabled: true,
      statuszArray: [],
      statuszOptions: [],
      filterOptions: {},
      bizonylattomb: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active) {
      if (this.props.active) {
        this.updateTable();
      }
    }
  }

  updateTable = () => {
    Services.listBizonylattomb((err, res) => {
      if (!err) {
        let filters = {};
        let options = res[0].statusz.map((e) => {
          filters[e.name] = e.caption;

          return <option key={e.name} value={e.name}>{e.caption}</option>;
        });

        this.setState({
          bizonylatTombJson: res[0].list,
          statuszArray: res[0].statusz,
          statuszOptions: options,
          filterOptions: filters,
          existsRaktar: res[0].existsraktar,
        });
      }
    });
  }

  setExistsRaktar = () => {
    Services.existsRaktar((err, res) => {
      if (!err) {
        this.setState({ existsRaktar: res[0].existsraktar });
      }
    });
  }

  getCegadatMezo = (cegadatId) => {
    Services.listAktivCegadat(cegadatId, (err, res) => {
      if (!err) {
        this.setState({
          cegadatArray: res[0],
          cegadatid: res[0].length === 1 ? res[0][0].id : ''
        });
      }
    });
  }

    /**
    * Lenyíló mezőhöz szükséges bizonylattípusok lekérdezése
    */
    getBizonylatTipusMezo = () => {
      Services.listAktivBizonylattipus((err, res) => {
        if (!err) {
          this.setState({
            bizonylatTipusArray: res[0],
            bizonylatTipusMezoInaktiv: false,
            bizonylattipus: res[0].length === 1 ? res[0][0].id : ''
          });
        }
      });
    }

  /**
  * Lenyíló mezőhöz szükséges raktárak lekérdezése
  */
  getRaktarMezo = (id, cegadatId, raktarMezoInaktiv) => {
    Services.listAktivRaktar(id, cegadatId, (err, res) => {
      if (!err) {
        this.setState({
          raktarArray: res[0],
          raktarMezoInaktiv: raktarMezoInaktiv,
          raktarid: !raktarMezoInaktiv && res[0].length === 1 ? res[0][0].id : ''
        });
      }
    });
  }

    /**
    * Paraméterként kapott bizonylattömb objektum felvétele adatbázisba.
    * @param {SytheticEvent} event
    * @param {object} biztombObj Bizonylattömb adatokat tartalamzó objektum.
    */
    addBizonylatTomb = (event, biztombObj) => {
      // Üres mezők null-ra alakítása
      for (let p in biztombObj) {
        if (biztombObj.hasOwnProperty(p)) {
          if (biztombObj[p] === '') {
            biztombObj[p] = null;
          }
        }
      }

      biztombObj.bizonylattipusid = biztombObj.bizonylattipus;

      Services.createBizonylattomb(biztombObj, (err) => {
        if (!err) {
          this.toggleRogzit();
          this.updateTable();
          this.props.globals.addSuccess('Bizonylattömb felvéve!');
        }
      });
    }

    /**
    * Paraméterként kapott bizonylattömb objektum módosítása adatbázisba.
    * @param {SytheticEvent} event
    * @param {object} biztombObj Bizonylattömb adatokat tartalamzó objektum.
    */
    modifyBizonylatTomb = (event, biztombObj) => {
      // Üres mezők null-ra alakítása
      for (let p in biztombObj) {
        if (biztombObj.hasOwnProperty(p)) {
          if (biztombObj[p] === '') {
            biztombObj[p] = null;
          }
        }
      }

      biztombObj.id = this.state.bizonylatTombModositObj.id;
      biztombObj.bizonylattipusid = biztombObj.bizonylattipus;

      Services.updateBizonylattomb(biztombObj, (err) => {
        if (!err) {
          this.toggleModosit();
          this.updateTable();
          this.props.globals.addSuccess('Bizonylattömb módosítva!');
        }
      });
    }

    /**
    * Paraméterben kapott bizonylattömb ID-jű rekord törlése adatbázisból
    * @param {number} id
    */
    deleteBizonylatTomb = (id) => {
      Services.deleteBizonylattomb(id, (err) => {
        if (!err) {
          this.toggleTorles();
          this.updateTable();
          this.props.globals.addSuccess('Bizonylattömb törölve!');
        }
      });
    }

    toggleNezet = () => {
      this.setState({
        modalNezet: !this.state.modalNezet
      });
    }

    toggleRogzit = () => {
      this.setState({
        modalRogzit: !this.state.modalRogzit,
      });
    }

    toggleModosit = () => {
      this.setState({
        modalModosit: !this.state.modalModosit
      });
    }

    toggleTorles = () => {
      this.setState({
        modalTorles: !this.state.modalTorles
      });
    }

    toggleLezaras = () => {
      this.setState({
        modalLezaras: !this.state.modalLezaras
      });
    }

    handleInputChange = (e) => {
      const { target } = e;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const { name } = target;
      this.setState({
        [name]: value
      });

      return value;
    }

    handleNewClick = () => {
      this.getCegadatMezo();
      this.getBizonylatTipusMezo();
      this.setExistsRaktar();
      this.setState({
        statusz: 'AKTIV',
        bizonylatTombModositObj: {},
        raktarArray: [],
        raktarid: '',
        raktarMezoInaktiv: true
      });

      this.toggleRogzit();
    }

    /** Megtekint gombra kattintás */
    handleViewClick = (id) => {
      Services.getBizonylattomb(id, (err, res) => {
        this._currentViewId = id;

        this.setExistsRaktar();
        this.setState({ bizonylattomb: res[0] });
        this.toggleNezet();
      });
    }

    /** Módosítás gombra kattintás */
    handleEditClick = (id) => {
      Services.getBizonylattomb(id, (err, res) => {
        if (!err) {
          this._currentEditId = id;

          let bt = res[0];
          for (let b in bt) {
            if (bt.hasOwnProperty(b)) {
              if ((Number(bt[b]) === bt[b] && bt[b] % 1 === 0) || typeof (bt[b]) === 'boolean') {
                bt[b] = bt[b].toString();
              }
            }
          }

          this.setState({
            bizonylatTombModositObj: bt,
          });

          this.getCegadatMezo(bt.cegadatid);
          this.getBizonylatTipusMezo();
          this.setExistsRaktar();
          if (this.state.existsRaktar) {
            this.getRaktarMezo(bt.raktarid, bt.cegadatid, !bt.bizonylattipusentity.raktarhozkapcs);
          }

          this.toggleModosit();
        }
      });
    }

    /** Törlés gombra kattintás */
    handleDeleteClick = (id) => {
      this._currentDeleteId = id;
      this.toggleTorles();
    }

    /** Lezárás gombra kattintás */
    handleLezarasClick = (id) => {
      this._currentDeleteId = id;
      this.toggleLezaras();
    }

    lockBizonylattomb = (id) => {
      Services.lockBizonylattomb(id, (err) => {
        if (!err) {
          this.toggleLezaras();
          this.updateTable();
          this.props.globals.addSuccess('Bizonylattömb lezárva!');
        }
      });
    }

    iconFormatter = (cell, row, globals) => {
      return (
        <div>
          {Lib.KeyCloak.hasHigherRole(globals, 'BIZONYLAT_TOMB', 'LEK') && (
            <React.Fragment>
              <Button className="icon-eye table-action-icon" id={'action-view-' + cell + 'megtekintes'} onClick={() => this.handleViewClick(cell)} />
              <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-view-' + cell + 'megtekintes'} delay={0}>
                Megtekintés
              </UncontrolledTooltip>
            </React.Fragment>
          )}
          {Lib.KeyCloak.hasHigherRole(globals, 'BIZONYLAT_TOMB', 'KARB') && (
            <React.Fragment>
              <Button className="icon-pencil table-action-icon" id={'action-view-' + cell + 'modositas'} onClick={() => this.handleEditClick(cell)} />
              <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-view-' + cell + 'modositas'} delay={0}>
                Módosítás
              </UncontrolledTooltip>
              <Button className="icon-trash table-action-icon" id={'action-view-' + cell + 'torles'} onClick={() => this.handleDeleteClick(cell)} />
              <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-view-' + cell + 'torles'} delay={0}>
                Törlés
              </UncontrolledTooltip>
              {row.statusz === 'AKTIV' && (
                <React.Fragment>
                  <Button className="fa fa-lock table-action-icon" id={'action-view-' + cell + 'lezaras'} onClick={() => this.handleLezarasClick(cell)} />
                  <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-view-' + cell + 'lezaras'} delay={0}>
                    Lezárás
                  </UncontrolledTooltip>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
      );
    }

    /**
    * Visszaadja a Bizonylattömbhöz tartozó státus jelvényeket.
    * @param {boolean} cell - Cella adatot tartalmazó szöveg.
    * @return {ReactElement} Státusz jelvényt tartalmazó react objektum.
    */
    statusFormatter = (cell) => {
      let statusz = this.state.statuszArray.find(x => x.name === cell);
      return statusz ? <span className={'badge badge-' + statusz.bagde}>{statusz.caption}</span> : 'N/A';
    }

    /**
    * Visszaadja a bizonylatTombJson paramtérben kapott id-jű elemének az adatait.
    * @param {number} id - Kiválaszott bizonylattomb id-ja.
    * @return {Array.ReactElement} bizonylattomb részletes információ.
    */
    renderReszletes = () => {
      let statusz = this.state.bizonylattomb && this.state.statuszArray.find(x => x.name === this.state.bizonylattomb.statusz);

      return (
        <div>
          <h6>Bizonylattömb információ:</h6>
          <b>Cégadat: </b> {this.state.bizonylattomb && this.state.bizonylattomb.cegadatnev}<br />
          <b>Bizonylattípus neve: </b> {this.state.bizonylattomb && this.state.bizonylattomb.bizonylattipusentity.nev}<br />
          <b>Bizonylattömb neve: </b> {this.state.bizonylattomb && this.state.bizonylattomb.nev}<br />
          <b>Szigorú számadású? </b> {this.state.bizonylattomb && this.state.bizonylattomb.szigoruszamadas && this.state.bizonylattomb.szigoruszamadas.toString() == 'true' ? 'Igen' : 'Nem'}<br />
          <b>Kézi bizonylat? </b> {this.state.bizonylattomb && this.state.bizonylattomb.kezi && this.state.bizonylattomb.kezi.toString() == 'true' ? 'Igen' : 'Nem'}<br />
          <b>Előtag: </b> {this.state.bizonylattomb && this.state.bizonylattomb.elotag}<br />
          <b>Utótag: </b> {this.state.bizonylattomb && this.state.bizonylattomb.utotag}<br />
          <b>Kezdő sorszám: </b> {this.state.bizonylattomb && this.state.bizonylattomb.kezdosorsz}<br />
          <b>Utolsó szorszám: </b> {this.state.bizonylattomb && this.state.bizonylattomb.utolsosorsz}<br />
          {this.state.existsRaktar && (
            <React.Fragment>
              <b>Raktár: </b>
                {this.state.bizonylattomb && this.state.bizonylattomb.raktarnev}
              <br />
            </React.Fragment>
          )}
          <b>Példányszám: </b> {this.state.bizonylattomb && this.state.bizonylattomb.peldanyszam}<br />
          <b>Státusz: </b> {statusz && statusz.caption}<br />
        </div>
      );
    }

    renderBizonylatTipusOptions = () => {
      return this.state.bizonylatTipusArray.map(e => <option key={e.id} value={e.id}>{e.nev}</option>);
    }

    renderCegadatOptions = () => {
      return this.state.cegadatArray.map(e => <option key={e.id} value={e.id}>{e.nev + ' (' + e.tipus + ')'}</option>);
    }

    renderRaktarOptions = () => {
      return !this.state.raktarMezoInaktiv ? this.state.raktarArray.map(e => <option key={e.id} value={e.id}>{e.nev}</option>) : [];
    }

    render() {
      const options = {
        sizePerPage: 5,
        paginationShowsTotal: PaginationTotal,
        sizePerPageList: [{
          text: '5', value: 5
        }, {
          text: '10', value: 10
        }, {
          text: '25', value: 25
        }],
      };

      const modifyObj = this.state.bizonylatTombModositObj;

      return (
        <div>
          {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'BIZONYLAT_TOMB', 'KARB') && (
            <div className="row">
              <div className="col-sm-12">
                <Button className="pull-left table-new-button" color="primary" onClick={this.handleNewClick}>Új bizonylattömb</Button>
              </div>
            </div>
          )}

          <BootstrapTable data={this.state.bizonylatTombJson} pagination options={options}>
            <TableHeaderColumn dataField="cegadatnev" filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Cégadat</TableHeaderColumn>
            <TableHeaderColumn dataField="tipusnev" filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Biz. típus név</TableHeaderColumn>
            <TableHeaderColumn dataField="nev" filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Biz. tömb név</TableHeaderColumn>
            <TableHeaderColumn dataField="elotag" filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Előtag</TableHeaderColumn>
            <TableHeaderColumn dataField="utotag" filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Utótag</TableHeaderColumn>
			<TableHeaderColumn dataField="raktarnev" filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }} hidden={!this.state.existsRaktar}>Raktár</TableHeaderColumn>
            <TableHeaderColumn
              dataField="statusz"
              width="120"
              dataFormat={this.statusFormatter}
              filter={{
                type: 'SelectFilter',
                options: this.state.filterOptions,
                placeholder: 'Összes',
                condition: 'eq'
              }}
              dataAlign="center"
            >
              Státusz
            </TableHeaderColumn>
            <TableHeaderColumn width="120" dataField="id" isKey dataFormat={(cell, row) => this.iconFormatter(cell, row, this.props.globals.keycloak)}>Műveletek</TableHeaderColumn>
          </BootstrapTable>


          <Modal isOpen={this.state.modalRogzit} toggle={this.toggleRogzit} className={this.props.className} backdrop="static" size="lg">
            <AvForm onValidSubmit={this.addBizonylatTomb}>
              <ModalHeader toggle={this.toggleRogzit}>Bizonylattömb felvétel</ModalHeader>
              <ModalBody>
                <Row>
                  <Col lg={6}>
                    <AvGroup>
                      <AvField
                        type="select"
                        name="cegadatid"
                        id="cegadatid"
                        label="Cégadat: *"
                        errorMessage="Kötelező mező!"
                        required
                        onChange={v => {
                          let value = this.handleInputChange(v);
                          if (value && this.state.existsRaktar) {
                            this.getRaktarMezo(null, value, this.state.raktarMezoInaktiv);
                          } else {
                            this.setState({ raktarid: '', raktarMezoInaktiv: true, raktarArray: [] });
                          }
                        }}
                        value={this.state.cegadatid}
                      >
                        <option value="">Válassz cégadatot...</option>
                        {this.renderCegadatOptions()}
                      </AvField>
                    </AvGroup>
                  </Col>
                  <Col lg={6}>
                    <AvGroup>
                      <AvField
                        type="select"
                        name="statusz"
                        value={this.state.statusz}
                        label="Státusz: *"
                        errorMessage="Kötelező mező!"
                        required
                        disabled={!this.state.cegadatid}
                      >
                        {this.state.statuszOptions}
                      </AvField>
                    </AvGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <AvGroup>
                      <AvField
                        type="select"
                        name="bizonylattipus"
                        id="bizonylattipus"
                        label="Bizonylattípus: *"
                        value=""
                        disabled={this.state.bizonylatTipusMezoInaktiv || !this.state.cegadatid}
                        errorMessage="Kötelező mező!"
                        onChange={(e) => {
                          let kapcs = this.state.bizonylatTipusArray && this.state.bizonylatTipusArray.filter(x => x.id === parseInt(e.target.value, 10))[0].raktarhozkapcs;
                          let raktarid;
                          if (!kapcs) {
                            raktarid = '';
                          } else {
                            raktarid = this.state.raktarArray.length === 1 ? this.state.raktarArray[0].id : this.state.raktarid;
                          }
                          this.setState({ raktarMezoInaktiv: !kapcs, raktarid: raktarid });
                        }}
                        required
                      >
                        <option value="" disabled>Válassz bizonylattípust...</option>
                        {this.renderBizonylatTipusOptions()}
                      </AvField>
                    </AvGroup>
                  </Col>

                  <Col lg={6}>
                    <AvGroup>
                      <Label for="nev">Bizonylattömb neve: *</Label>
                      <AvInput
                        name="nev"
                        id="nev"
                        maxLength="50"
                        required disabled={!this.state.cegadatid}
                      />
                      <AvFeedback>Kötelező mező!</AvFeedback>
                    </AvGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <AvGroup>
                      <AvField
                        type="select"
                        onChange={e => this.setState({ szigoruszamadas: e.target.value })}
                        name="szigoruszamadas"
                        id="SzigoruFelvitel"
                        label="Szigorú számadású: *"
                        value="false"
                        errorMessage="Kötelező mező!"
                        required
                        disabled={!this.state.cegadatid}
                      >
                        <option value="false">Nem</option>
                        <option value="true">Igen</option>
                      </AvField>
                    </AvGroup>
                    <UncontrolledTooltip placement="right" target="SzigoruFelvitel">
                                Annak a jelölése, hogy a bizonylat szigorú számadású-e, tehát a sorszámok egymást követik.
                    </UncontrolledTooltip>
                  </Col>

                  <Col lg={6}>
                    <AvGroup>
                      <AvField
                        type="select"
                        onChange={e => this.setState({ kezi: e.target.value })}
                        name="kezi"
                        value="false"
                        label="Kézi bizonylat:"
                        disabled={!this.state.cegadatid}
                      >
                        <option value="false">Nem</option>
                        <option value="true">Igen</option>
                      </AvField>
                    </AvGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <AvGroup>
                      <Label id="ElotagFelvitel" for="elotag">Előtag/Tömbjel: *</Label>
                      <AvInput
                        name="elotag"
                        id="elotag"
                        maxLength="17"
                        required
                        disabled={!this.state.cegadatid}
                      />
                      <AvFeedback>Kötelező mező!</AvFeedback>
                    </AvGroup>
                    <UncontrolledTooltip placement="right" target="ElotagFelvitel">
                                A Bizonylat kezdő karakterei.
                    </UncontrolledTooltip>
                  </Col>

                  <Col lg={6}>
                    <AvGroup>
                      <Label for="utotag">Utótag/Évszám: *</Label>
                      <AvInput
                        name="utotag"
                        id="utotag"
                        maxLength="6"
                        required
                        disabled={!this.state.cegadatid}
                        />
                      <AvFeedback>Kötelező mező!</AvFeedback>
                    </AvGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <AvGroup>
                      <Label for="kezdosorsz">Kezdő sorszám: *</Label>
                      <AvInput
                        name="kezdosorsz"
                        id="kezdosorsz"
                        maxLength="10"
                        required
                        disabled={!this.state.cegadatid}
                        />
                      <AvFeedback>Kötelező mező!</AvFeedback>
                    </AvGroup>
                  </Col>

                  <Col lg={6}>
                    <AvGroup>
                      <Label for="utolsosorsz">Utolsó sorszám: *</Label>
                      <AvInput
                        name="utolsosorsz"
                        id="utolsosorsz"
                        maxLength="10"
                        required
                        disabled={!this.state.cegadatid}
                      />
                      <AvFeedback>Kötelező mező!</AvFeedback>
                    </AvGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <AvGroup>
                      <Label for="peldanyszam">Példányszám: *</Label>
                      <AvInput
                        name="peldanyszam"
                        id="peldanyszam"
                        maxLength="3"
                        required
                        disabled={!this.state.cegadatid}
                        />
                      <AvFeedback>Kötelező mező!</AvFeedback>
                    </AvGroup>
                  </Col>

                  {this.state.existsRaktar && (
                    <Col lg={6}>
                      <AvGroup>
                        <AvField
                          type="select"
                          name="raktarid"
                          id="raktarid"
                          label={'Raktár:'}
                          disabled={this.state.raktarMezoInaktiv || !this.state.cegadatid}
                          value={this.state.raktarid}
                          onChange={this.handleInputChange}
                          required={!this.state.raktarMezoInaktiv}
                          errorMessage="Kötelező mező!"
                        >
                          <option value="">Válassz raktárat...</option>
                          {this.renderRaktarOptions()}
                        </AvField>
                      </AvGroup>
                    </Col>
                  )}
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button color="primary">Mentés</Button>
                {' '}
                <Button type="button" color="secondary" onClick={this.toggleRogzit}>Mégse</Button>
              </ModalFooter>
            </AvForm>
          </Modal>


          <Modal isOpen={this.state.modalModosit} toggle={this.toggleModosit} className={this.props.className} backdrop="static" size="lg">
            <AvForm onValidSubmit={this.modifyBizonylatTomb}>
              <ModalHeader toggle={this.toggleModosit}>Bizonylattömb módosítás</ModalHeader>
              <ModalBody>
                <Row>
                  <Col lg={6}>
                    <AvGroup>
                      <AvField
                        type="select"
                        name="cegadatid"
                        id="cegadatid"
                        label="Cégadat: *"
                        required
                        errorMessage="Kötelező mező!"
                        onChange={v => {
                          let value = this.handleInputChange(v);
                          if (value && this.state.existsRaktar) {
                            this.getRaktarMezo(null, value, this.state.raktarMezoInaktiv);
                          } else {
                            modifyObj.raktarid = '';
                          }
                          modifyObj.cegadatid = value;
                          
                          this.setState({ bizonylatTombModositObj: modifyObj, raktarArray: [], raktarMezoInaktiv: true });
                        }}
                        value={modifyObj.cegadatid}
                      >
                        <option value="">Válassz cégadatot...</option>
                        {this.renderCegadatOptions()}
                      </AvField>
                    </AvGroup>
                  </Col>
                  <Col lg={6}>
                    <AvGroup>
                      <AvField
                        type="select"
                        name="statusz"
                        value={modifyObj.statusz}
                        label="Státusz: *"
                        errorMessage="Kötelező mező!"
                        required
                        disabled={modifyObj && !modifyObj.cegadatid}
                      >
                        {this.state.statuszOptions}
                      </AvField>
                    </AvGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <AvGroup>
                      <AvField
                        type="select"
                        name="bizonylattipus"
                        id="bizonylattipus"
                        label="Bizonylattípus: *"
                        value={modifyObj && modifyObj.bizonylattipusentity && modifyObj.bizonylattipusentity.id}
                        disabled={this.state.bizonylatTipusMezoInaktiv || (modifyObj && !modifyObj.cegadatid)}
                        onChange={(e) => {
                          let kapcs = this.state.bizonylatTipusArray && this.state.bizonylatTipusArray.filter(x => x.id === parseInt(e.target.value, 10))[0].raktarhozkapcs;
                          let value = '';
                          if (kapcs) {
                            value = this.state.raktarArray.length === 1 ? this.state.raktarArray[0].id : modifyObj.raktarid;
                          }
                          modifyObj.raktarid = value;
                          this.setState({ raktarMezoInaktiv: !kapcs });
                        }}
                        required
                        errorMessage="Kötelező mező!"
                      >
                        <option value="" disabled>Válassz bizonylattípust...</option>
                        {this.renderBizonylatTipusOptions()}
                      </AvField>
                    </AvGroup>
                  </Col>

                  <Col lg={6}>
                    <AvGroup>
                      <Label for="nev">Bizonylattömb neve: *</Label>
                      <AvInput
                        name="nev"
                        id="nev"
                        value={modifyObj.nev}
                        maxLength="50"
                        required
                        disabled={modifyObj && !modifyObj.cegadatid}
                      />
                      <AvFeedback>Kötelező mező!</AvFeedback>
                    </AvGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <AvGroup>
                      <AvField
                        type="select"
                        onChange={e => this.setState({ szigoruszamadas: e.target.value })}
                        name="szigoruszamadas"
                        id="SzigoruModosit"
                        label="Szigorú számadású: *"
                        value={modifyObj.szigoruszamadas}
                        errorMessage="Kötelező mező!"
                        required
                        disabled={modifyObj && !modifyObj.cegadatid}
                      >
                        <option value="false">Nem</option>
                        <option value="true">Igen</option>
                      </AvField>
                    </AvGroup>
                    <UncontrolledTooltip placement="right" target="SzigoruModosit">
                                Annak a jelölése, hogy a bizonylat szigorú számadású-e, tehát a sorszámok egymást követik.
                    </UncontrolledTooltip>
                  </Col>

                  <Col lg={6}>
                    <AvGroup>
                      <AvField
                        type="select"
                        onChange={e => this.setState({ kezi: e.target.value })}
                        name="kezi"
                        label="Kézi bizonylat:"
                        value={modifyObj.kezi}
                        disabled={modifyObj && !modifyObj.cegadatid}
                      >
                        <option value="false">Nem</option>
                        <option value="true">Igen</option>
                      </AvField>
                    </AvGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <AvGroup>
                      <Label id="ElotagModositas" for="elotag">Előtag/Tömbjel: *</Label>
                      <AvInput
                        name="elotag"
                        id="elotag"
                        value={modifyObj.elotag}
                        maxLength="17"
                        required
                        disabled={modifyObj && !modifyObj.cegadatid}
                      />
                      <AvFeedback>Kötelező mező!</AvFeedback>
                    </AvGroup>
                    <UncontrolledTooltip placement="right" target="ElotagModositas">
                                A Bizonylat kezdő karakterei.
                    </UncontrolledTooltip>
                  </Col>

                  <Col lg={6}>
                    <AvGroup>
                      <Label for="utotag">Utótag/Évszám: *</Label>
                      <AvInput
                        name="utotag"
                        id="utotag"
                        value={modifyObj.utotag}
                        maxLength="6"
                        required
                        disabled={modifyObj && !modifyObj.cegadatid}
                      />
                      <AvFeedback>Kötelező mező!</AvFeedback>
                    </AvGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <AvGroup>
                      <Label for="kezdosorsz">Kezdő sorszám: *</Label>
                      <AvInput
                        name="kezdosorsz"
                        id="kezdosorsz"
                        value={modifyObj.kezdosorsz}
                        maxLength="10"
                        required
                        disabled={modifyObj && !modifyObj.cegadatid}
                       />
                      <AvFeedback>Kötelező mező!</AvFeedback>
                    </AvGroup>
                  </Col>

                  <Col lg={6}>
                    <AvGroup>
                      <Label for="utolsosorsz">Utolsó sorszám: *</Label>
                      <AvInput
                        name="utolsosorsz"
                        id="utolsosorsz"
                        value={modifyObj.utolsosorsz}
                        maxLength="10"
                        required
                        disabled={modifyObj && !modifyObj.cegadatid}
                      />
                      <AvFeedback>Kötelező mező!</AvFeedback>
                    </AvGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <AvGroup>
                      <Label for="peldanyszam">Példányszám: *</Label>
                      <AvInput
                        name="peldanyszam"
                        id="peldanyszam"
                        value={modifyObj.peldanyszam}
                        maxLength="3"
                        required
                        disabled={modifyObj && !modifyObj.cegadatid}
                        />
                      <AvFeedback>Kötelező mező!</AvFeedback>
                    </AvGroup>
                  </Col>

                  {this.state.existsRaktar && (
                    <Col lg={6}>
                      <AvGroup>
                        <AvField
                          type="select"
                          name="raktarid"
                          id="raktarid"
                          label="Raktár:"
                          value={modifyObj.raktarid}
                          onChange={v => {
                            let value = this.handleInputChange(v);
        
                            modifyObj.raktarid = value;
                            this.setState({ bizonylatTombModositObj: modifyObj});
                          }}
                          disabled={this.state.raktarMezoInaktiv || (modifyObj && !modifyObj.cegadatid)}
                          required={!this.state.raktarMezoInaktiv}
                        >
                          <option value="">Válassz raktárat...</option>
                          {this.renderRaktarOptions()}
                        </AvField>
                      </AvGroup>
                    </Col>
                  )}
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button color="primary">Mentés</Button>
                {' '}
                <Button type="button" color="secondary" onClick={this.toggleModosit}>Mégse</Button>
              </ModalFooter>
            </AvForm>
          </Modal>


          <Modal isOpen={this.state.modalTorles} toggle={this.toggleTorles} className={this.props.className}>
            <ModalHeader toggle={this.toggleTorles}>Törlés</ModalHeader>
            <ModalBody>
                          Valóban törölni kívánod a kiválasztott tételt?
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => this.deleteBizonylatTomb(this._currentDeleteId)}>OK</Button>
              <Button type="button" color="secondary" onClick={this.toggleTorles}>Mégsem</Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={this.state.modalLezaras} toggle={this.toggleLezaras} className={this.props.className}>
            <ModalHeader toggle={this.toggleLezaras}>Lezárás</ModalHeader>
            <ModalBody>
              Valóban kívánod lezárni a kiválasztott tételt?
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => this.lockBizonylattomb(this._currentDeleteId)}>OK</Button>
              <Button type="button" color="secondary" onClick={this.toggleLezaras}>Mégsem</Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={this.state.modalNezet} toggle={this.toggleNezet} className={this.props.className} backdrop="static">
            <ModalHeader>Megtekintés</ModalHeader>
            <ModalBody>
              {this.state.modalNezet ? this.renderReszletes(this._currentViewId) : <div />}
            </ModalBody>
            <ModalFooter>
              <Button className="btn-cursor-pointer" type="button" color="primary" onClick={this.toggleNezet}>Vissza</Button>
            </ModalFooter>
          </Modal>
        </div>
      );
    }
}

BizonylattombLista.propTypes = {
  globals: PropTypes.object,
  active: PropTypes.bool,
  className: PropTypes.string,
};

export default BizonylattombLista;
