import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
  AvField
} from 'availity-reactstrap-validation';
import {
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { browserHistory } from 'react-router';
import DropZone from 'react-dropzone';
import Select from 'react-select/lib/Async';
import fileSaver from 'file-saver';
import Lib from '../../commons/lib';
import Services from './services';
import { DatePicker, FileCard } from '../../commons/controls';
import SlaveAvInput from '../../commons/SlaveAvInput';
import NumberFormatter from '../../commons/NumberFormatter';

class SzerzodesUrlap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fizTeljElerheto: true,
      currentId: null,
      dataLoaded: false,
      modositoObj: null,
      modositoStatus: false,
      felvetelStatus: false,
      szallitoError: false,
      szallitoValue: '',
      vevoError: false,
      vevoValue: [],
      szamlafizetoValue: '',
      checkboxChecked: false,
      modalModok: false,
      jogtulajdonosok: [],
      files: [],
      aktivCegadat: [],
      aktivPenznem: [],
      aktivFizetesimod: [],
      szerzodesTipusok: [],
      szamlasUtemezes: [],
      szamlaNyelv: [],
      statusz: [],
      hosszabbitas: [],
      cegadatValue : '',
    };
  }

  componentWillMount() {
    this.getSzerzodesTipus();

    let params = new URL(window.location.href).searchParams;
    let id = params.get('id');
    this.setState({ currentId: id });

    Services.initFormSzerzodes(id, false, (err, res) => {
      if (!err) {
        this.setState({
          aktivCegadat: res[0].cegadat.map(e => <option key={e.id} value={e.id}>{e.nev}</option>),
          aktivPenznem: res[0].penznem.map(e => <option key={e.id} value={e.id}>{e.nev + ' (' + e.azonosito + ')'}</option>),
          aktivFizetesimod: res[0].fizmod.map(e => <option key={e.variansid ? e.variansid : e.id} value={e.variansid ? e.variansid : e.id}>{e.variansnev ? e.variansnev : e.nev}</option>),
          szamlasUtemezes: res[0].fizetesutemezes.map(e => <option key={e.name} value={e.name}>{e.caption}</option>),
          szamlaNyelv: res[0].szamlanyelv.map(e => <option key={e.name} value={e.name}>{e.caption}</option>),
          statusz: res[0].statusz.map(e => <option key={e.name} value={e.name}>{e.caption}</option>),
          hosszabbitas: res[0].hosszabitas.map(e => <option key={e.name} value={e.name}>{e.caption}</option>),
        });

        if (this.props.route.modositas === 'true') {
          if (id) {
            let szallitoNev = res[0].szerzodes.szallito.nev + ' ( ' + res[0].szerzodes.szallito.cim + ' ) ';
            if (res[0].szerzodes.szallito.adoszam) {
              szallitoNev += '[' + NumberFormatter.addHyphensToString(res[0].szerzodes.szallito.adoszam) + '] ';
            }
            res[0].szerzodes.szallito.nev = szallitoNev;
            let files = res[0].szerzodes.objectParam;

            let newArray = [];
            res[0].szerzodes.jogosultak.forEach((item) => {
              item.bean.nev += ' (' + item.bean.cim + ') ';
              if (item.bean.adoszam) {
                item.bean.nev += '[' + NumberFormatter.addHyphensToString(item.bean.adoszam) + '] ';
              }
              newArray.push(item);
            });
            this.setState({
              jogtulajdonosok: newArray,
              modositoObj: res[0].szerzodes,
              fizTeljElerheto: res[0].szerzodes.szerzodestipusentity.kod !== 'UDULJ',
              szallitoValue: res[0].szerzodes.szallito,
              checkboxChecked: res[0].szerzodes.elterofelek,
              szamlafizetoValue: res[0].szerzodes.szamlafizeto,
              dataLoaded: true,
              files: files,
              cegadatValue: res[0].szerzodes.cegadatid
            });
          }
        } else {
          this.setState({
            modositoObj: null,
            dataLoaded: true,
            cegadatValue: res[0].cegadat.length === 1 ? res[0].cegadat[0].id : ''
          });
          this.addJogtulajdonosRow();
        }
      }
    });
  }

  componentDidMount() {
    this.onMount();
  }

  onMount = () => {
    if (this.props.route.modositas === 'true') {
      this.setState({ modositoStatus: true });
    }
    if (this.props.route.felvetel === 'true') {
      this.setState({ felvetelStatus: true });
    }
  }

  onDropObject = (acceptedfiles) => {
    acceptedfiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const fileAsBinaryString = reader.result;
        let files = this.state.files || [];
        files.push({
          content: btoa(fileAsBinaryString),
          uploadDate: new Date(),
          docname: file.name,
          preview: file.preview,
          mime: file.type || 'application/octet-stream',
          id: null,
          length: file.size,
          preid: Lib.Browser.uuidv4()
        });
        this.setState({ files: files });
      };
      reader.readAsBinaryString(file);
    });
  }

  onCheckboxChange = (event) => {
    let isTrueSet = (event.target.value === 'true') || (event.target.value === true);
    this.setState({ checkboxChecked: !isTrueSet });
  }

  getSzerzodesTipus = () => {
    Services.listSzerzodesTipus((err, res) => {
      if (!err) {
        let resOptions = res[0].map(e => <option key={e.id} value={e.id}>{e.nev}</option>);
        this.setState({ szerzodesTipusok: resOptions });
      }
    });
  }

  toggleModok = () => {
    this.setState({ modalModok: !this.state.modalModok });
  }

  handleChangeSzallito = (value) => {
    if (value && value.length !== 0) {
      this.setState({ szallitoValue: value, szallitoError: false });
    } else {
      this.setState({ szallitoValue: '' });
    }
  }

  handleChangeVevo = (value, idx) => {
    let newVevoValue = this.state.vevoValue;
    let newState = this.state.jogtulajdonosok;
    if (value && value.length !== 0) {
      newState[idx].bean = value;
      newState[idx].partnertortenetid = value.id;
      this.setState({ vevoValue: newVevoValue, jogtulajdonosok: newState });
    } else {
      newState[idx].bean.id = null;
      newState[idx].partnertortenetid = null;
      this.setState({ vevoValue: '', jogtulajdonosok: newState });
    }
  }

  handleChangeSzamlafizeto = (value) => {
    if (value && value.length !== 0) {
      this.setState({ szamlafizetoValue: value.id });
    } else {
      this.setState({ szamlafizetoValue: '' });
    }
  }

  downloadObject = (file) => {
    if (file.preview) {
      window.open(file.preview, '_blank');
    } else {
      this.getContent(file.id);
    }
  }

  removeObject = (file) => {
    if (file.preid) {
      let files = this.state.files.filter(f => f.preid !== file.preid);
      this.setState({ files: files });
    } else {
      let files = this.state.files.filter(f => f.id !== file.id);
      this.setState({ files: files });
    }
  }

  addJogtulajdonosRow = () => {
    let newJogtulajdonosok = this.state.jogtulajdonosok;
    newJogtulajdonosok.push({
      partnertortenetid: '',
      bean: null
    });
    this.setState({ jogtulajdonosok: newJogtulajdonosok });
  }

  getContent = (objid) => {
    let obj = this.state.files.find(f => f.id == objid);
    Services.downloadObject(objid, (err, res) => {
      if (!err) {
        fileSaver.saveAs(new Blob([res[0]], { type: obj.mime }), obj.docname);
      }
    });
  }

  formatBytes = (bytes) => {
    if (bytes < 1024) return bytes + ' bájt';
    if (bytes < 1048576) return (bytes / 1024).toFixed(3) + ' KB';
    if (bytes < 1073741824) return (bytes / 1048576).toFixed(3) + ' MB';
    return (bytes / 1073741824).toFixed(3) + ' GB';
  };

  navToList = () => {
    browserHistory.push({
      pathname: '/szerzodes',
      state: this.props.location && this.props.location.state
    });
  }

  mentes = (event, szerzodesObj) => {
    let submitObj = szerzodesObj;
    submitObj.osszeg = NumberFormatter.removeSpacesFromString(submitObj.osszeg);
    if (szerzodesObj.iktatoszam === '') { delete submitObj.iktatoszam; }
    if (this.state.szallitoValue === '') { this.setState({ szallitoError: true }); }
    if (this.state.vevoValue === '') { this.setState({ vevoError: true }); }
    if (szerzodesObj.azonosito === '') { delete submitObj.azonosito; }
    if (szerzodesObj.szerzodesHosszabbitas === '') { delete submitObj.szerzodesHosszabbitas; }
    submitObj.jogosultak = [];
    this.state.jogtulajdonosok.forEach((item) => {
      submitObj.jogosultak.push({
        partnertortenetid: item.partnertortenetid,
      });
    });

    if (this.state.modositoStatus) {
      submitObj.id = this.state.currentId;
      submitObj.szerzodestipusentity = { id: szerzodesObj.tipus };
      if (this.state.szallitoValue && this.state.szallitoValue.id) {
        submitObj.szallitoId = this.state.szallitoValue.id;
      } else {
        submitObj.szallitoId = null;
      }
      submitObj.vevoId = this.state.vevoValue.id;
      if (this.state.szamlafizetoValue && this.state.szamlafizetoValue.id) {
        submitObj.szamlafizetoid = this.state.szamlafizetoValue.id;
      } else {
        submitObj.szamlafizetoid = this.state.szamlafizetoValue;
      }
      submitObj.objectParam = this.state.files;

      Services.updateSzerzodes(submitObj, (err) => {
        if (!err) {
          this.props.route.globals.addSuccess('Szerződés módosítva!');
          this.navToList();
        }
      });
    } else {
      submitObj.szerzodestipusentity = { id: szerzodesObj.tipus };
      if (this.state.szallitoValue && this.state.szallitoValue.id) {
        submitObj.szallitoId = this.state.szallitoValue.id;
      } else {
        submitObj.szallitoId = null;
      }
      submitObj.szamlafizetoid = this.state.szamlafizetoValue;
      // submitObj.udulesijogtulajdonosentities = this.state.jogtulajdonosok;
      submitObj.objectParam = this.state.files;
      Services.createSzerzodes(submitObj, (err) => {
        if (!err) {
          this.props.route.globals.addSuccess('Szerződés felvéve!');
          this.navToList();
        }
      });
    }
  }

  deleteJogtulajdonos = (idx) => {
    let newState = this.state.jogtulajdonosok;
    newState.splice(idx, 1);
    this.setState({
      jogtulajdonosok: newState
    });
  }

  loadSzallitoChange = (input, callback) => {
    if (input.length !== 0) {
      Services.getAktivPartnerByCsoport('SZERZODES_SZALLITO_FORM', input, null, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            item.nev += ' (' + item.cim + ') ';
            if (item.adoszam) {
              item.nev += '[' + NumberFormatter.addHyphensToString(item.adoszam) + '] ';
            }
            newArray.push(item);
          });
          callback(newArray);
        }
      });
    }
  }

  loadVevoChange = (input, callback) => {
    if (input.length !== 0) {
      Services.getAktivPartnerByCsoport('SZERZODES_VEVO_FORM', input, null, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            item.nev += ' (' + item.cim + ') ';
            if (item.adoszam) {
              item.nev += '[' + NumberFormatter.addHyphensToString(item.adoszam) + '] ';
            }
            newArray.push(item);
          });
          callback(newArray);
        }
      });
    }
  }

  loadSzamlafizetoChange = (input, callback) => {
    if (input.length !== 0) {
      Services.getAktivPartnerByCsoport('APJT', input, this.state.vevoValue, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            item.nev += ' (' + item.cim + ') ';
            if (item.adoszam) {
              item.nev += '[' + NumberFormatter.addHyphensToString(item.adoszam) + '] ';
            }
            newArray.push(item);
          });
          callback(null, { options: newArray });
        }
      });
    }
  }

  tipusChange = (e) => {
    // TODO: Üdülési jog választás esetén false különben igaz
    this.setState({ fizTeljElerheto: false });
  }

  renderLoader = () => {
    return (
      <div className="showbox">
        <div className="loader">
          <svg className="circular" viewBox="25 25 50 50">
            <circle className="path" cx="50" cy="50" r="20" fill="none" />
          </svg>
        </div>
      </div>
    );
  }

  renderDefaultForm = () => {
    return (
      <div className="row">
        <div className="col-md-4">
          <AvField
            type="select"
            name="tipus"
            id="tipus"
            label="Típus: "
            errorMessage="Kötelező mező!"
            disabled={this.state.modositoStatus}
            required
            value={this.state.modositoObj && this.state.modositoObj.szerzodestipusentity.id}
            onChange={e => this.tipusChange(e)}
          >
            <option disabled value="">Válassz típust...</option>
            {this.state.szerzodesTipusok}
          </AvField>
        </div>
        <div className="col-md-4">
          <AvGroup>
            <Label for="azonosito">Szerződés azonosító: </Label>
            <AvInput name="azonosito" id="azonosito" maxLength="30" disabled={this.state.modositoStatus} value={this.state.modositoObj && this.state.modositoObj.azonosito} />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-4">
          <AvGroup>
            <Label for="iktatoszam">Iktatószám: </Label>
            <AvInput name="iktatoszam" id="iktatoszam" maxLength="30" value={this.state.modositoObj && this.state.modositoObj.iktatoszam} />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-4">
          <AvGroup>
            <Label for="bizonylatszam">Bizonylatszám: </Label>
            <AvInput name="bizonylatszam" id="bizonylatszam" maxLength="30" disabled value={this.state.modositoObj && this.state.modositoObj.bizonylatszam} />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-4">
          <AvField type="select" name="statusz" id="statusz" label="Állapot: " errorMessage="Kötelező mező!" required value={this.state.modositoObj && this.state.modositoObj.statusz.name}>
            <option disabled value="">Válassz állapotot...</option>
            {this.state.statusz}
          </AvField>
        </div>
        <div className="col-md-4">
          <AvField type="select" name="cegadatid" id="cegadatid" label="Cégadat: *" required errorMessage="Kötelező mező!" value={this.state.cegadatValue} disabled={this.state.modositoStatus}>
            <option value="">Válassz cégadatot...</option>
            {this.state.aktivCegadat}
          </AvField>
        </div>
        <div className="col-md-12">
          <AvGroup>
            <Label for="targy">Szerződés tárgya:</Label>
            <AvInput className="munkalap-profil-textarea" type="textarea" name="targy" id="targy" maxLength="350" required value={this.state.modositoObj && this.state.modositoObj.targy} />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className={this.state.szallitoError ? 'col-md-10 text-danger form-group' : 'col-md-10 form-group'}>
          <Label for="szallitoId">Szállító:</Label>
          {/* <Select.Async
            name="szallitoId"
            id="szallitoId"
            placeholder="Kezdjen el gépelni a kereséshez..."
            noResultsText="Nincs keresési eredmény"
            searchPromptText="Kezdjen el gépelni a kereséshez..."
            loadingPlaceholder="Keresés..."
            onChange={v => this.handleChangeSzallito(v)}
            clearable
            ignoreAccents={false}
            loadOptions={_.debounce((input, callback) => this.loadSzallitoChange(input, callback), 1000)}
            isLoading={false}
            valueKey="id"
            labelKey="nev"
            value={this.state.szallitoValue}
            cache={false}
            autoload={false}
            disabled={this.state.fizTeljElerheto}
            required
          /> */}
          <Select
            styles={{
              control: base => ({
                ...base,
                '&:focus': { borderColor: 'red' }, // border style on hover
                border: '1px solid lightgray', // default border color
                boxShadow: 'none', // no box-shadow
                borderRadius: '0'
              }),
            }}
            name="szallitoId"
            id="szallitoId"
            placeholder="Kezdjen el gépelni..."
            noOptionsMessage={() => 'Nincs keresési eredmény'}
            searchPromptText="Kezdjen el gépelni a kereséshez..."
            loadingMessage={() => 'Keresés...'}
            onChange={v => this.handleChangeSzallito(v)}
            isClearable
            ignoreAccents={false}
            loadOptions={_.debounce(this.loadSzallitoChange, 1000)}
            isLoading={false}
            getOptionLabel={option => option.nev}
            getOptionValue={option => option.id}
            value={this.state.szallitoValue}
            isDisabled={this.state.fizTeljElerheto}
            cache={false}
            autoload={false}
          />
          {this.state.szallitoError ? (<div>Kötelező mező!</div>) : <span />}
        </div>
        {this.renderJogosultak()}
        <div className="col-md-12">
          <Button className="pull-left table-new-button" color="success" onClick={() => this.addJogtulajdonosRow()}>Jogosult hozzáadása</Button>
        </div>
      </div>
    );
  }

  renderJogosultak = () => {
    let jogtulajdonosok = [];
    this.state.jogtulajdonosok.forEach((item, idx) => {
      jogtulajdonosok.push(
        <Fragment key={idx.toString()}>
          <div className={this.state.vevoError ? 'col-md-10 text-danger form-group' : 'col-md-10 form-group'} key={idx.toString()}>
            <Label for="vevoId">Jogosult:</Label>
            {/* <Select.Async
              name="vevoId"
              id="vevoId"
              placeholder="Kezdjen el gépelni a kereséshez..."
              noResultsText="Nincs keresési eredmény"
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingPlaceholder="Keresés..."
              onChange={v => this.handleChangeVevo(v, idx)}
              ignoreAccents={false}
              clearable={false}
              loadOptions={_.debounce((input, callback) => this.loadVevoChange(input, callback), 1000)}
              isLoading={false}
              valueKey="id"
              labelKey="nev"
              value={this.state.jogtulajdonosok[idx].bean}
              cache={false}
              autoload={false}
            /> */}
            <Select
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="vevoId"
              id="vevoId"
              placeholder="Kezdjen el gépelni..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingMessage={() => 'Keresés...'}
              onChange={v => this.handleChangeVevo(v, idx)}
              // isClearable
              ignoreAccents={false}
              loadOptions={_.debounce(this.loadVevoChange, 1000)}
              isLoading={false}
              getOptionLabel={option => option.nev}
              getOptionValue={option => option.id}
              value={this.state.jogtulajdonosok[idx].bean}
              cache={false}
              autoload={false}
            />
            {this.state.vevoError ? (<div>Kötelező mező!</div>) : <span />}
          </div>
          <div className="col-md-2">
            <Button color="danger" style={{ marginTop: '29px' }} type="button" onClick={() => this.deleteJogtulajdonos(idx)}>Törlés</Button>
            {' '}
          </div>
        </Fragment>
      );
    });
    return (
      jogtulajdonosok
    );
  }

  renderAltalanosForm = () => {
    let titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12">
            <div><h5 style={titleStyle}>Általános adatok:</h5></div>
          </div>
          <div className="col-md-4">
            <Label for="alairasdatum">Aláírás dátuma:</Label>
            <DatePicker
              required
              max={new Date()}
              type="select"
              name="alairasdatum"
              defaultValue={this.state.modositoObj && this.state.modositoObj.alairasdatum && new Date(this.state.modositoObj.alairasdatum)}
            />
          </div>
          <div className="col-md-4">
            <Label for="kezdete">Időtartam kezdete:</Label>
            <DatePicker
              required
              type="select"
              name="kezdete"
              defaultValue={this.state.modositoObj && this.state.modositoObj.kezdete && new Date(this.state.modositoObj.kezdete)}
            />
          </div>
          <div className="col-md-4">
            <Label for="vege">Időtartam vége:</Label>
            <DatePicker
              required
              type="select"
              name="vege"
              defaultValue={this.state.modositoObj && this.state.modositoObj.vege && new Date(this.state.modositoObj.vege)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <AvField
              type="select"
              name="szerzodesHosszabbitas"
              id="szerzodesHosszabbitas"
              label="Szerződés hosszabbítás: "
              value={this.state.modositoObj && this.state.modositoObj.szerzodesHosszabbitas && this.state.modositoObj.szerzodesHosszabbitas.name}
            >
              <option value="">Válassz szerződés hosszabbítást...</option>
              {this.state.hosszabbitas}
            </AvField>
          </div>
          <div className="col-md-4">
            <Label for="kezdete">Nyilatkozat dátuma:</Label>
            <DatePicker
              type="select"
              name="nyilatkozatDatum"
              defaultValue={this.state.modositoObj && this.state.modositoObj.nyilatkozatDatum && new Date(this.state.modositoObj.nyilatkozatDatum)}
              // defaultValue={this.state.modositoObj && this.state.modositoObj.kezdete && new Date(this.state.modositoObj.kezdete)}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderPenzugyiForm = () => {
    let titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };

    return (
      <div className="row">
        <div className="col-md-12">
          <div><h5 style={titleStyle}>Pénzügyi adatok:</h5></div>
        </div>
        <div className="col-md-4">
          <SlaveAvInput
            id="osszeg"
            label="Szerződés összege:"
            value={this.state.modositoObj && this.state.modositoObj.osszeg}
            szuroTipus={NumberFormatter.OSSZEG}
          />
        </div>
        <div className="col-md-4">
          <AvField type="select" name="penznemid" id="penznemid" label="Pénznem: " errorMessage="Kötelező mező!" value={this.state.modositoObj && this.state.modositoObj.penznemid}>
            <option disabled value="">Válassz pénznemet...</option>
            {this.state.aktivPenznem}
          </AvField>
        </div>
        <div className="col-md-4">
          <AvField type="select" name="fizmodid" id="fizmodid" label="Fizetési mód: " errorMessage="Kötelező mező!" value={this.state.modositoObj && this.state.modositoObj.fizmodid}>
            <option disabled value="">Válassz fizetési módot...</option>
            {this.state.aktivFizetesimod}
          </AvField>
        </div>
        <div className="col-md-4">
          <AvField type="select" name="utemezes" id="utemezes" label="Számlázás ütemezése: " errorMessage="Kötelező mező!" required value={(this.state.modositoObj && this.state.modositoObj.utemezes) && this.state.modositoObj.utemezes.name}>
            <option disabled value="">Válassz ütemezést...</option>
            {this.state.szamlasUtemezes}
          </AvField>
        </div>
        <div className="col-md-4">
          <AvField type="select" name="nyelv" id="nyelv" label="Számlázás nyelve: " errorMessage="Kötelező mező!" required value={this.state.modositoObj && this.state.modositoObj.nyelv.name}>
            <option disabled value="">Válassz nyelvet...</option>
            {this.state.szamlaNyelv}
          </AvField>
        </div>
        {this.state.fizTeljElerheto ? (
          <div className="col-md-4">
            <Label for="fizhatarido">Fizetési határidő:</Label>
            <DatePicker
              required
              type="select"
              name="fizhatarido"
              defaultValue={this.state.modositoObj && this.state.modositoObj.fizhatarido && new Date(this.state.modositoObj.fizhatarido)}
            />
          </div>
        ) : <div />}
        {this.state.fizTeljElerheto ? (
          <div className="col-md-4">
            <Label for="teljhatarido">Teljesítés határideje:</Label>
            <DatePicker
              required
              type="select"
              name="teljhatarido"
              defaultValue={this.state.modositoObj && this.state.modositoObj.teljhatarido && new Date(this.state.modositoObj.teljhatarido)}
            />
          </div>
        ) : <div />}
        <div className="col-md-8">
          <AvGroup>
            <Label for="teljmegjegyzes">Megjegyzés (teljesítés):</Label>
            <AvInput name="teljmegjegyzes" id="teljmegjegyzes" maxLength="150" value={this.state.modositoObj && this.state.modositoObj.teljmegjegyzes} />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-12">
          <AvGroup>
            <Label for="megjegyzes">Megjegyzés:</Label>
            <AvInput className="munkalap-profil-textarea" type="textarea" name="megjegyzes" id="megjegyzes" maxLength="350" value={this.state.modositoObj && this.state.modositoObj.megjegyzes} />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        {/* <div className="col-md-12">
          <AvGroup>
            <Label check >
              <AvInput type="checkbox" name="elterofelek" onChange={this.onCheckboxChange} value={this.state.modositoObj && this.state.modositoObj.elterofelek}  /> Számlafizető és szerződő fél eltér
            </Label>
          </AvGroup>
        </div>
        {this.state.checkboxChecked ?
          <div className="col-md-12">
            <div className="col-md-12 form-group">
              <Label for="szamlafizetoid">Számlafizető:</Label>
              <Select.Async
                name="szamlafizetoid"
                id="szamlafizetoid"
                placeholder="Kezdjen el gépelni a kereséshez..."
                noResultsText="Nincs keresési eredmény"
                searchPromptText="Kezdjen el gépelni a kereséshez..."
                loadingPlaceholder="Keresés..."
                onChange={v => this.handleChangeSzamlafizeto(v)}
                clearable
                ignoreAccents={false}
                loadOptions={_.debounce((input, callback) => this.loadSzamlafizetoChange(input, callback), 1000)}
                isLoading={false}
                valueKey="id"
                labelKey="nev"
                value={this.state.szamlafizetoValue}
                cache={false}
                autoload={false}
              />
            </div>
          </div> : <span />} */}
        <div className="col-md-12">
          {this.renderFileUpload()}
        </div>
        <div className="col-md-12 margin-top-30">
          <Button color="primary">
            {'Mentés'}
          </Button>
          {' '}
          <Button type="button" color="secondary" onClick={this.navToList}>
            {'Mégse'}
          </Button>
        </div>
      </div>
    );
  }

  renderFileUpload = () => {
    let titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };

    return (
      <div className="row">
        <div className="col-md-12">
          <div><h5 style={titleStyle}>Fájl feltöltése:</h5></div>
        </div>
        <div className="col-md-12">
          <DropZone
            multiple
            onDrop={this.onDropObject}
            className="custom-dropzone"
          >
            <div className="dropzone-text">
              {'Húzd ide a fájlokat vagy kattints a négyzetbe egy kép feltöltéséhez!'}
            </div>
          </DropZone>
        </div>
        {this.state.files && this.state.files.map((item, idx) => {
          return (
            <div className="col-md-4" key={idx.toString()}>
              <FileCard
                key={idx.toString()}
                title={item.docname}
                length={this.formatBytes(item.length)}
                onDownloadClick={() => this.downloadObject(item)}
                onDeleteClick={() => this.removeObject(item)}
              />
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    let cardStyle = {
      padding: 20
    };

    return (
      <div>
        <div className="animated fadeIn">
          <div className="card">
            <div className="card-header">
              {this.state.modositoStatus ? 'Szerződés módosítása' : 'Új szerződés' }
            </div>
            <div style={cardStyle}>
              {this.state.dataLoaded ? (
                <AvForm onValidSubmit={this.mentes}>
                  {this.renderDefaultForm()}
                  {this.renderAltalanosForm()}
                  {this.renderPenzugyiForm()}
                </AvForm>
              ) : this.renderLoader()}
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.modalModok}
          toggle={this.toggleModok}
          className={this.props.className}
          backdrop="static"
        >
          <ModalHeader>Módosítás mentése</ModalHeader>
          <ModalBody>
            <AvForm onValidSubmit={this.sas}>
              <AvGroup>
                <Label for="modoka">Módosítás oka: </Label>
                <AvInput name="modoka" id="modoka" required />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
            </AvForm>
          </ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" color="primary">OK</Button>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleModok}>Mégsem</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default SzerzodesUrlap;
