import moment from "moment";

/**
 * .+ kerülendő, mert ha a sorban van másik lezáró zárójel, akkor a .+ miatt közre fog fogni mindent az első és utolsó zárójelek között -RK
 * 
 * A javított verzióban csak a betűk vannak közrefogva az első capturing group-ban
 * Ez helyett itt van egy regex, ami közrefog minden lehetséges kombinációt amivel találkoztam, 
 * de ezt óvatosan lehet csak használni, mert lehetséges olyan sor, amin közre fog fogni többet, mint ami szándékozva van
 * Példuál a moment(date).format(format)-ban ez a bemenet zárójelek között a várt (date) helyett (date).format(format)-t fogja megtalálni
 * [a-zA-Z\.\(\)\[\]\s0-9&|]+
 * 
 * Moment Replace Regex
 * - Search: moment\((\w+)\).format\(('|")YYYYMMDD('|")\)
 * - Search javított: moment\(([a-zA-Z]+)\)\.format\(('|")YYYYMMDD('|")\)
 * - Replace: TimeFormatter.formatIso($1)
 *
 * MomentTimezone Replace Regex
 * - Search: MomentTimezone.tz\((.+),\sthis.tz\).format\(('|")YYYY-MM-DD HH:mm('|")\)
 * - Search javított: MomentTimezone.tz\(([a-zA-Z]+),\sthis.tz\)\.format\(('|")YYYY-MM-DD HH:mm('|")\)
 * - Replace: TimeFormatter.formatDate($1)
 * 
 * MomentTimezone Custom Format Replace
 * - Search: MomentTimezone.tz\((.+),\sthis.tz\).format\(('|")(.+)('|")\)
 * - Search javított: MomentTimezone.tz\(([a-zA-Z]+),\sthis.tz\)\.format\(('|")([a-zA-Z\.:-]+)('|")\)
 * - Replace: TimeFormatter.format($1, "$3")
 * 
 * Moment Replace Regex
 * - Search: moment\((\w+)\).format\(('|")(.+)('|")\)
 * - Search javított: moment\(([a-zA-Z]+)\)\.format\(('|")([a-zA-Z\.:-]+)('|")\)
 * - Replace: TimeFormatter.format($1, "$3")
 */

class TimeFormatter {
  static FORMATS = {
    datetime: "YYYY-MM-DD HH:mm:ss",
    datetimeWithoutSeconds: "YYYY-MM-DD HH:mm",
    date: "YYYY-MM-DD",
    time: "HH:mm:ss",
    // Például 1999-01-01T00:00:00.000+0200
    iso: "YYYY-MM-DDTHH:mm:ss.SSSZZ",
    // Például 1999-01-30T22:00:00.000Z
    isoZ: "YYYY-MM-DDTHH:mm:ss.SSS[Z]",
    isoDate: "YYYY-MM-DDT00:00:00.000ZZ",
    fullDate: "YYYY-MM-DDTHH:mm",
    dateNoDots: "YYYYMMDD",
    // Teljes dátum formátum a locale-tól függően
    LLLL: "LLLL",
  };

  static format(date, format = TimeFormatter.FORMATS.datetime) {
    if (!date) return null;
    return moment(date).format(format);
  }

  static formatDateTime(date) {
    return TimeFormatter.format(date, TimeFormatter.FORMATS.datetime);
  }

  static formatDateTimeWithoutSeconds(date) {
    return TimeFormatter.format(date, TimeFormatter.FORMATS.datetimeWithoutSeconds);
  }

  static formatDate(date) {
    return TimeFormatter.format(date, TimeFormatter.FORMATS.date);
  }

  static formatTime(date) {
    return TimeFormatter.format(date, TimeFormatter.FORMATS.time);
  }

  static formatIso(date) {
    return TimeFormatter.format(date, TimeFormatter.FORMATS.iso);
  }

  static formatIsoZ(date) {
    let dt = moment(date);
    dt = dt.subtract(dt.parseZone().utcOffset(), 'minutes');
    return TimeFormatter.format(dt, TimeFormatter.FORMATS.isoZ);
  }

  static formatIsoDate(date) {
    return TimeFormatter.format(date, TimeFormatter.FORMATS.isoDate);
  }

  static formatFullDate(date) {
    return TimeFormatter.format(date, TimeFormatter.FORMATS.fullDate);
  }

  static formatDateNoDots(date) {
    return TimeFormatter.format(date, TimeFormatter.FORMATS.dateNoDots);
  }

  static formatLLLL(date) {
    return TimeFormatter.format(date, TimeFormatter.FORMATS.LLLL);
  }
}

export default TimeFormatter;
