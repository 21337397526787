import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  Button,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  UncontrolledTooltip
} from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { browserHistory } from 'react-router';
import Select from 'react-select/lib/Async';
import fileSaver from 'file-saver';
import DataEmptyState from '../../commons/dataemptystate';
import NetworkEmptyState from '../../commons/networkemptystate';
import QueryEmptyState from '../../commons/queryempty';
import Services from './services';
import { FileCard, PaginationTotal } from '../../commons/controls';
import Lib from '../../commons/lib';
import Loader from '../../commons/loader';
import NumberFormatter from '../../commons/NumberFormatter';

class SzerzodesTabla extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // ['QUERY', 'LOAD', 'DATA', 'NETW', 'TABL']
      currentStatusBaseTable: 'QUERY',
      // ['LOAD', 'LOADED', 'NETW']
      currentStatusBaseView: 'LOAD',
      szerzodesObj: null,
      szallitoValue: null,
      vevoValue: null,
      szerzodesBizszamValue: null,
      modalTorles: false,
      modalNezet: false,
      modalLetoltes: false,
      szerzodesJson: [],
      files: [],
      currentId: null,
    };

    this.updateTable = this.updateTable.bind(this);
  }

  componentDidMount() {
    if (this.props.location && this.props.location.state && this.props.location.state.type && this.props.location.state.type === 'SZERZODES') {
      this.updateEloszures(this.props.location.state.szallitoid, this.props.location.state.jogosultid);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.tableData !== nextProps.tableData) {
      this.setState({
        currentStatusBaseTable: 'LOAD'
      });
      Services.listSzerzodesByUdulesijogId(nextProps.tableData, (err, res) => {
        if (err) {
          this.setState({
            currentStatusBaseTable: 'NETW'
          });
        } else {
          if (res[0].length === 0) this.props.globals.addInfo('Az üdülési joghoz nem tartozik szerződés!');
          let newState = res[0] ? res[0] : [];
          newState.forEach((item) => {
            switch (item.statusz.name) {
              case 'TERVEZET':
                item.statusz = 0;
                break;
              case 'ALAIRT':
                item.statusz = 1;
                break;
              case 'LEZART':
                item.statusz = 2;
                break;
              default:
                item.statusz = -1;
                break;
            }
          });
          this.setState({
            szerzodesJson: newState,
            currentStatusBaseTable: newState.length > 0 ? 'TABL' : 'DATA'
          });
        }
      });
    }
  }

  updateEloszures = (szallitoid, jogosultid) => {
    this.setState({
      szallitoValue: szallitoid,
      vevoValue: jogosultid
    }, () => {
      this.updateTable();
    });
  }

  toggleTorles = () => {
    this.setState({ modalTorles: !this.state.modalTorles });
  }

  toggleNezet = () => {
    this.setState({ modalNezet: !this.state.modalNezet });
  }

  toggleLetoltesNezet = () => {
    this.setState({ modalLetoltes: !this.state.modalLetoltes });
  }

  handleDeleteClick = (id) => {
    this._currentDeleteId = id;
    this.toggleTorles();
  }

  handleEditClick = (id, row) => {
    this._currentEditId = row.id;
    this.navToModositas(row.id);
  }

  handleNewChildClick = (id) => {
    browserHistory.push('/szerzodes/udulesijog/felvetel?id=' + id);
  }

  handleViewClick = (id) => {
    this.setState({
      currentStatusBaseView: 'LOAD',
      modalNezet: true,
      currentId: id,
    });
    Services.initFormSzerzodes(id, true, (err, res) => {
      if (err) {
        this.setState({
          currentStatusBaseView: 'NETW'
        });
      } else {
        this.setState({
          szerzodesObj: res[0].szerzodes,
          currentStatusBaseView: 'LOADED'
        });
      }
    });
  }

  handleFileDownloadClick = (id) => {
    this.toggleLetoltesNezet();
    Services.getSzerzodes(id, (err, res) => {
      if (!err) {
        this.setState({
          files: res[0].objectParam
        });
      }
    });
  }

  updateTable = () => {
    this.setState({
      currentStatusBaseTable: 'LOAD'
    });
    if (!(this.state.szallitoValue || this.state.vevoValue || this.state.szerzodesBizszamValue)) {
      this.setState({
        currentStatusBaseTable: 'QUERY'
      });
      // this.props.globals.addError('Az előszűréshez legalább az egyik mező megadása kötelező!');
    } else {
      Services.listSzerzodes(this.state.szallitoValue && this.state.szallitoValue.id, this.state.vevoValue && this.state.vevoValue.id, this.state.szerzodesBizszamValue && this.state.szerzodesBizszamValue.b, (err, res) => {
        if (err) {
          this.setState({
            currentStatusBaseTable: 'NETW'
          });
        } else {
          let newState = res[0];
          newState.forEach((item) => {
            switch (item.statusz.name) {
              case 'TERVEZET':
                item.statusz = 0;
                break;
              case 'ALAIRT':
                item.statusz = 1;
                break;
              case 'LEZART':
                item.statusz = 2;
                break;
              default:
                item.statusz = -1;
                break;
            }
          });
          this.setState({
            szerzodesJson: newState,
            currentStatusBaseTable: newState.length > 0 ? 'TABL' : 'DATA'
          }, () => {
            if (this.props.location && this.props.location.state && this.props.location.state.key) {
              // this.kuldesTabla.state.currPage = this.kuldesTabla.getPageByRowKey(parseInt(this.props.location.state.key, 10));
            }
          });
        }
      });
    }
  }

  deleteSzerzodes = (id) => {
    this.toggleTorles();
    Services.deleteSzerzodes(id, (err) => {
      if (!err) {
        this.props.globals.addSuccess('Szerződés törölve!');
        this.updateTable();
      }
    });
  }

  handleChangeSzallito = (value) => {
    if (value && value.length !== 0) {
      this.setState({
        szallitoValue: {
          id: value.id,
          nev: value.nev,
        }
      });
    } else {
      this.setState({ szallitoValue: '' });
    }
  }

  handleChangeVevo = (value) => {
    if (value && value.length !== 0) {
      this.setState({
        vevoValue: {
          id: value.id,
          nev: value.nev,
        }
      });
    } else {
      this.setState({ vevoValue: '' });
    }
  }

  handleChangeSzerzodesBizszam = (value) => {
    if (value && value.length !== 0) {
      this.setState({
        szerzodesBizszamValue: {
          b: value.b,
        }
      });
    } else {
      this.setState({ szerzodesBizszamValue: '' });
    }
  }

  // handleInputChangeAzonosito = (value) => {
  //   if (value && value.length !== '') {
  //     this.setState({
  //       szerzodesBizszamValue: {
  //         b: value,
  //       }
  //     });
  //   } else {
  //     this.setState({ szerzodesBizszamValue: '' });
  //   }
  // }

  handleInputChangeAzonosito = (value, { action }) => {
    switch (action) {
      case 'input-change':
        if (value && value.length !== '') {
          this.setState({
            szerzodesBizszamValue: {
              b: value,
            }
          });
        } else {
          this.setState({ szerzodesBizszamValue: '' });
        }
        break;
      default:
    }
  }

  loadSzallitoChange = (input, callback) => {
    if (input.length !== 0) {
      Services.listSzallitoVevo('SZERZODES_SZALLITO_ELO', input, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            let newItem = item;
            newItem.nev += ' (' + newItem.cim + ') ';
            if (newItem.adoszam) {
              newItem.nev += '[' + NumberFormatter.addHyphensToString(newItem.adoszam) + '] ';
            }
            newArray.push(newItem);
          });
          callback(newArray);
        }
      });
    }
  }

  loadVevoChange = (input, callback) => {
    if (input.length !== 0) {
      Services.listSzallitoVevo('SZERZODES_VEVO_ELO', input, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            let newItem = item;
            newItem.nev += ' (' + newItem.cim + ') ';
            if (newItem.adoszam) {
              newItem.nev += '[' + NumberFormatter.addHyphensToString(newItem.adoszam) + '] ';
            }
            newArray.push(newItem);
          });
          callback(newArray);
        }
      });
    }
  }

  loadSzerzodesBizszamChange = (input, callback) => {
    if (input.length !== 0) {
      Services.listSzerzodesBizonylatFilterValues(input, (err, res) => {
        if (!err) {
          callback(res[0]);
        }
      });
    }
  }

  formatBytes = (bytes) => {
    if (bytes < 1024) return bytes + ' bájt';
    if (bytes < 1048576) return (bytes / 1024).toFixed(3) + ' KB';
    if (bytes < 1073741824) return (bytes / 1048576).toFixed(3) + ' MB';
    return (bytes / 1073741824).toFixed(3) + ' GB';
  };

  iconFormatter = (cell, row) => {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'SZERZODES_SZERZODES', 'LEK') && (
          <React.Fragment>
            <Button className="icon-eye table-action-icon" id={'action-view-' + cell} onClick={() => this.handleViewClick(cell)} />
            <UncontrolledTooltip placement="left" target={'action-view-' + cell} delay={0}>
              {'Megtekintés'}
            </UncontrolledTooltip>
            <Button className="fa fa-cloud-download table-action-icon" id={'action-download-' + cell} onClick={() => this.handleFileDownloadClick(cell, row)} />
            <UncontrolledTooltip placement="left" target={'action-download-' + cell} delay={0}>
              {'Dokumentumok'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'SZERZODES_SZERZODES', 'KARB') && (
          <React.Fragment>
            <Button className="icon-pencil table-action-icon" id={'action-edit-' + cell} onClick={() => this.handleEditClick(cell, row)} />
            <UncontrolledTooltip placement="left" target={'action-edit-' + cell} delay={0}>
              {'Módosítás'}
            </UncontrolledTooltip>
            <Button className="icon-trash table-action-icon" id={'action-delete-' + cell} onClick={() => this.handleDeleteClick(cell)} />
            <UncontrolledTooltip placement="left" target={'action-delete-' + cell} delay={0}>
              {'Törlés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </div>
    );
  }

  allapotFormatter = (cell) => {
    switch (cell) {
      case 0:
        return <Badge color="info">Tervezet</Badge>;
      case 1:
        return <Badge color="success">Aláírt</Badge>;
      case 2:
        return <Badge color="secondary">Lezárt</Badge>;
      default:
        return <Badge color="danger">Ismeretlen</Badge>;
    }
  }

  vevoFormatter = (cell) => {
    return cell.nev + ', ' + cell.cim;
  }

  navToModositas = (id) => {
    browserHistory.push({
      pathname: '/szerzodes/szerzodes/modositas',
      search: '?id=' + id,
      state: {
        type: 'SZERZODES',
        key: id,
        szallitoid: this.state.szallitoValue,
        jogosultid: this.state.vevoValue,
        szallodaid: null,
        epuletid: null,
        partner: null
      }
    });
  }

  navToForm = () => {
    browserHistory.push({
      pathname: '/szerzodes/szerzodes/felvetel',
      state: {
        type: 'SZERZODES',
        szallitoid: null,
        jogosultid: null,
        szallodaid: null,
        epuletid: null,
        partner: null
      }
    });
  }

  renderAdatok = () => {
    let jogosultList = [];
    if (this.state.szerzodesObj && this.state.szerzodesObj.jogosultak) {
      this.state.szerzodesObj.jogosultak.forEach((item) => {
        jogosultList.push(<li><b>Jogosult neve: </b>{item.bean.nev}<br /></li>);
        jogosultList.push(<span><b>Jogosult címe: </b>{item.bean.cim}</span>);
      });
    }
    return (
      <div>
        <h6>Szerződés információ:</h6>
        <b>Típus: </b>{this.state.szerzodesObj && this.state.szerzodesObj.szerzodestipusentity && this.state.szerzodesObj.szerzodestipusentity.nev}<br />
        <b>Szerződés azonosító: </b>{this.state.szerzodesObj && this.state.szerzodesObj.azonosito}<br />
        <b>Iktatószám: </b>{this.state.szerzodesObj && this.state.szerzodesObj.iktatoszam}<br />
        <b>Bizonylatszám: </b>{this.state.szerzodesObj && this.state.szerzodesObj.bizonylatszam}<br />
        <b>Állapot: </b>{this.state.szerzodesObj && this.state.szerzodesObj.statusz && this.state.szerzodesObj.statusz.caption}<br />
        <b>Cégadat: </b>{this.state.szerzodesObj && this.state.szerzodesObj.cegadatnev}<br />
        <b>Szerződés tárgya: </b>{this.state.szerzodesObj && this.state.szerzodesObj.targy}<br />
        <b>Szállító neve: </b>{this.state.szerzodesObj && this.state.szerzodesObj.szallito && this.state.szerzodesObj.szallito.nev}<br />
        <b>Szállító címe: </b>{this.state.szerzodesObj && this.state.szerzodesObj.szallito && this.state.szerzodesObj.szallito.cim}<br />
        {/* <b>Számlafizető neve: </b>{this.state.szerzodesObj && this.state.szerzodesObj.szamlafizeto && this.state.szerzodesObj.szamlafizeto.nev}<br />
        <b>Számlafizető címe: </b>{this.state.szerzodesObj && this.state.szerzodesObj.szamlafizeto && this.state.szerzodesObj.szamlafizeto.cim}<br /><br /> */}<br/>
        <h6>Jogosultak:</h6>
        <ul>
          {jogosultList}
        </ul>
        <br />
        <h6>Általános adatok:</h6>
        <b>Aláírás dátuma: </b>{this.state.szerzodesObj && this.state.szerzodesObj.alairasdatum}<br />
        <b>Időtartam kezdete: </b>{this.state.szerzodesObj && this.state.szerzodesObj.kezdete}<br />
        <b>Időtartam vége: </b>{this.state.szerzodesObj && this.state.szerzodesObj.vege}<br /><br />
        <h6>Pénzügyi adatok:</h6>
        <b>Szerződés összege: </b>{this.state.szerzodesObj && NumberFormatter.addSpacesToString(this.state.szerzodesObj.osszeg, NumberFormatter.OSSZEG)}<br />
        <b>Pénznem: </b>{this.state.szerzodesObj && this.state.szerzodesObj.penznem}<br />
        <b>Fizetési mód: </b>{this.state.szerzodesObj && this.state.szerzodesObj.fizmod}<br />
        <b>Számlázás ütemezése: </b>{this.state.szerzodesObj && this.state.szerzodesObj.utemezes && this.state.szerzodesObj.utemezes.caption}<br />
        <b>Számlázás nyelve: </b>{this.state.szerzodesObj && this.state.szerzodesObj.nyelv && this.state.szerzodesObj.nyelv.caption}<br />
        <b>Fizetési határidő: </b>{this.state.szerzodesObj && this.state.szerzodesObj.fizhatarido}<br />
        <b>Teljesítés határideje: </b>{this.state.szerzodesObj && this.state.szerzodesObj.teljhatarido}<br />
        <b>Megjegyzés (teljesítés): </b>{this.state.szerzodesObj && this.state.szerzodesObj.teljmegjegyzes}<br />
        <b>Megjegyzés: </b>{this.state.szerzodesObj && this.state.szerzodesObj.megjegyzes}<br />
      </div>
    );
  }

  onRowSelect = (row) => {
    this.props.changeData(row.id);
  }

  getFilextFromName = (filename) => {
    return filename.split('.').pop();
  }

  downloadObject = (obj) => {
    Services.downloadObject(obj.id, (err, res) => {
      if (!err) {
        fileSaver.saveAs(new Blob([res[0]], { type: obj.mime }), obj.docname);
      }
    });
  }

  viewObject = (obj) => {
    Services.downloadObject(obj.id, (err, res) => {
      if (!err) {
        let fileURL = URL.createObjectURL(new Blob([res[0]], { type: 'application/pdf' }));
        window.open(fileURL);
      }
    });
  }

  objIsViewable = (filename) => {
    if (Lib.getFilextFromName(filename) === 'pdf' || Lib.getFilextFromName(filename) === 'jpg' || Lib.getFilextFromName(filename) === 'png') return true;
    return false;
  }

  renderFiles = () => {
    let files = [];
    if (this.state.files) {
      this.state.files.forEach((item, idx) => {
        files.push(<FileCard key={idx.toString()} id={item.id} title={item.docname} length={this.formatBytes(item.length)} onDownloadClick={() => this.downloadObject(item)} onViewClick={this.objIsViewable(item.docname) ? () => this.viewObject(item) : undefined} />);
      });
    }
    return files;
  }

  renderTable = () => {
    const selectRowProp = {
      mode: 'radio',
      onSelect: this.onRowSelect
    };

    const statuszok = {
      0: 'Tervezet',
      1: 'Aláírt',
      2: 'Lezárt'
    };

    const options = {
      // page: this.props.currentPage
      sizePerPage: 5,
      paginationShowsTotal: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };

    return (
      <div className="animated fadeIn">
        <BootstrapTable
          ref={((c) => { this.kuldesTabla = c; })}
          data={this.state.szerzodesJson}
          pagination
          options={options}
          selectRow={selectRowProp}
        >
          <TableHeaderColumn
            dataField="azonosito"
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            width="200"
          >
            {'Szerződés azonosító'}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="bizonylatszam"
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            width="200"
          >
            {'Bizonylatszám'}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="statusz"
            dataAlign="center"
            dataFormat={this.allapotFormatter}
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'SelectFilter', options: statuszok, placeholder: 'Összes' }}
            width="160"
          >
            {'Állapot'}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="vevo"
            tdStyle={{ whiteSpace: 'pre-line' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
          >
            {'Jogosult'}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="targy"
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
          >
            {'Tárgy'}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="id"
            tdStyle={{ whiteSpace: 'normal' }}
            isKey
            dataFormat={this.iconFormatter}
            width="160"
          >
            {'Műveletek'}
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  }

  render() {
    return (
      <div>
        <AvForm onValidSubmit={this.updateTable}>
          <div className="row">
            <div className="col-md-3">
              <Label for="szallitoId">Szállító:</Label>
              {/* <Select.Async
                name="szallitoId"
                id="szallitoId"
                placeholder="Kezdjen el gépelni..."
                noResultsText="Nincs keresési eredmény"
                searchPromptText="Kezdjen el gépelni a kereséshez..."
                loadingPlaceholder="Keresés..."
                onChange={v => this.handleChangeSzallito(v)}
                clearable
                ignoreAccents={false}
                loadOptions={_.debounce((input, callback) => this.loadSzallitoChange(input, callback), 1000)}
                isLoading={false}
                valueKey="id"
                labelKey="nev"
                value={this.state.szallitoValue}
                cache={false}
                autoload={false}
              /> */}
              <Select
                styles={{
                  control: base => ({
                    ...base,
                    '&:focus': { borderColor: 'red' }, // border style on hover
                    border: '1px solid lightgray', // default border color
                    boxShadow: 'none', // no box-shadow
                    borderRadius: '0'
                  }),
                }}
                name="szallitoId"
                id="szallitoId"
                placeholder="Kezdjen el gépelni..."
                noOptionsMessage={() => 'Nincs keresési eredmény'}
                searchPromptText="Kezdjen el gépelni a kereséshez..."
                loadingMessage={() => 'Keresés...'}
                onChange={v => this.handleChangeSzallito(v)}
                isClearable
                ignoreAccents={false}
                loadOptions={_.debounce(this.loadSzallitoChange, 1000)}
                isLoading={false}
                getOptionLabel={option => option.nev}
                getOptionValue={option => option.id}
                value={this.state.szallitoValue}
                cache={false}
                autoload={false}
              />
            </div>
            <div className="col-md-3">
              <Label for="vevoId">Jogosult:</Label>
              {/* <Select.Async
                name="vevoId"
                id="vevoId"
                placeholder="Kezdjen el gépelni..."
                noResultsText="Nincs keresési eredmény"
                searchPromptText="Kezdjen el gépelni a kereséshez..."
                loadingPlaceholder="Keresés..."
                onChange={v => this.handleChangeVevo(v)}
                clearable
                ignoreAccents={false}
                loadOptions={_.debounce((input, callback) => this.loadVevoChange(input, callback), 1000)}
                isLoading={false}
                valueKey="id"
                labelKey="nev"
                value={this.state.vevoValue}
                cache={false}
                autoload={false}
              /> */}
              <Select
                styles={{
                  control: base => ({
                    ...base,
                    '&:focus': { borderColor: 'red' }, // border style on hover
                    border: '1px solid lightgray', // default border color
                    boxShadow: 'none', // no box-shadow
                    borderRadius: '0'
                  }),
                }}
                name="vevoId"
                id="vevoId"
                placeholder="Kezdjen el gépelni..."
                noOptionsMessage={() => 'Nincs keresési eredmény'}
                searchPromptText="Kezdjen el gépelni a kereséshez..."
                loadingMessage={() => 'Keresés...'}
                onChange={v => this.handleChangeVevo(v)}
                isClearable
                ignoreAccents={false}
                loadOptions={_.debounce(this.loadVevoChange, 1000)}
                isLoading={false}
                getOptionLabel={option => option.nev}
                getOptionValue={option => option.id}
                value={this.state.vevoValue}
                cache={false}
                autoload={false}
              />
            </div>
            <div className="col-md-3">
              <Label for="azonositoSzer">Bizonylatszám:</Label>
              {/* <Select.Async
                name="azonositoSzer"
                id="azonositoSzer"
                placeholder="Kezdjen el gépelni..."
                noResultsText="Nincs keresési eredmény"
                searchPromptText="Kezdjen el gépelni a kereséshez..."
                loadingPlaceholder="Keresés..."
                onChange={v => this.handleChangeSzerzodesBizszam(v)}
                onInputChange={v => this.handleInputChangeAzonosito(v)}
                clearable
                ignoreAccents={false}
                loadOptions={_.debounce((input, callback) => this.loadSzerzodesBizszamChange(input, callback), 1000)}
                isLoading={false}
                valueKey="b"
                labelKey="b"
                value={this.state.szerzodesBizszamValue}
                cache={false}
                autoload={false}
              /> */}
              <Select
                styles={{
                  control: base => ({
                    ...base,
                    '&:focus': { borderColor: 'red' }, // border style on hover
                    border: '1px solid lightgray', // default border color
                    boxShadow: 'none', // no box-shadow
                    borderRadius: '0'
                  }),
                }}
                name="azonositoSzer"
                id="azonositoSzer"
                placeholder="Kezdjen el gépelni..."
                noOptionsMessage={() => 'Nincs keresési eredmény'}
                searchPromptText="Kezdjen el gépelni a kereséshez..."
                loadingMessage={() => 'Keresés...'}
                onChange={v => this.handleChangeSzerzodesBizszam(v)}
                onInputChange={this.handleInputChangeAzonosito}
                isClearable
                ignoreAccents={false}
                loadOptions={_.debounce(this.loadSzerzodesBizszamChange, 1000)}
                isLoading={false}
                getOptionLabel={option => option.b}
                getOptionValue={option => option.b}
                value={this.state.szerzodesBizszamValue}
                cache={false}
                autoload={false}
              />
            </div>
            <div className="col-md-2">
              <Button className="margin-top-30" color="primary" onClick={this.collapseSzerzodes}>Keresés</Button>
            </div>
          </div>
        </AvForm>
        <div className="row margin-top-30">
          {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'SZERZODES_SZERZODES', 'KARB') && (
            <div className="col-md-12">
              <Button className="szerzodes-collapse-buttons" color="primary" onClick={this.navToForm}>Új szerződés</Button>
            </div>
          )}
          <div className="col-md-12">
            {{
              QUERY: (<QueryEmptyState
                hasImage
              />),
              LOAD: (<Loader />),
              DATA: (<DataEmptyState
                hasImage
                subtitle="Próbálj más előszűrést vagy vigyél fel új adatokat!"
              />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.updateTable()}
              />),
              TABL: (this.renderTable())
            }[this.state.currentStatusBaseTable]}
          </div>
        </div>
        <Modal isOpen={this.state.modalTorles} toggle={this.toggleTorles} className={this.props.className} backdrop="static">
          <ModalHeader>Törlés</ModalHeader>
          <ModalBody>
            {'Valóban törölni kívánja a kiválasztott tételt?'}
          </ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" color="primary" onClick={() => this.deleteSzerzodes(this._currentDeleteId)}>OK</Button>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleTorles}>Mégsem</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalNezet} toggle={this.toggleNezet} className={this.props.className} backdrop="static">
          <ModalHeader>Megtekintés</ModalHeader>
          <ModalBody>
            {{
              LOAD: (<Loader />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.handleViewClick(this.state.currentId)}
              />),
              LOADED: (this.renderAdatok())
            }[this.state.currentStatusBaseView]}
          </ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleNezet}>Vissza</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalLetoltes} toggle={this.toggleLetoltesNezet} className={this.props.className} backdrop="static">
          <ModalHeader>Dokumentumok megtekintése</ModalHeader>
          <ModalBody>
            {this.renderFiles()}
          </ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleLetoltesNezet}>Vissza</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

SzerzodesTabla.propTypes = {
  tableData: PropTypes.number,
  className: PropTypes.string,
  globals: PropTypes.object,
  keycloak: PropTypes.object,
  changeData: PropTypes.func
};

export default SzerzodesTabla;
