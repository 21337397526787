import Lib from '../../../commons/lib';

export default class Service {
  static bizonylatUrl = window.location.origin + '/api/bizonylat/';

  static raktarUrl = window.location.origin + '/api/raktar/';

  static cegadatUrl = window.location.origin + '/api/cegadat/';

  // BIZONYLATTÍPUS START

  static listBizonylattipus(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.bizonylatUrl + 'biztipus/list',
      args: { requestType: 'bizonylat-biztipus-list' }
    }], fnDone);
  }

  static createBizonylattipus(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.bizonylatUrl + 'biztipus',
      args: { requestType: 'bizonylat-create' },
      data: data,
    }], fnDone);
  }

  static updateBizonylattipus(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.bizonylatUrl + 'biztipus',
      args: { requestType: 'bizonylat-update' },
      data: data,
    }], fnDone);
  }

  static deleteBizonylattipus(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.bizonylatUrl + 'biztipus',
      args: { requestType: 'bizonylat-delete' },
      params: { id: id },
    }], fnDone);
  }

  // BIZONYLATTÍPUS END

  // BIZONYLATTÖMB START

  static listBizonylattomb(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.bizonylatUrl + 'biztomb/list',
      args: { requestType: 'bizonylat-biztomb-list' }
    }], fnDone);
  }

  static getBizonylattomb(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.bizonylatUrl + 'biztomb',
      args: { requestType: 'bizonylat-biztomb-get' },
      params: { id: id }
    }], fnDone);
  }

  static listAktivBizonylattipus(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.bizonylatUrl + 'biztipus/aktiv',
      args: { requestType: 'bizonylat-biztipus-aktiv' }
    }], fnDone);
  }

  static listAktivRaktar(id, cegadatId, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.raktarUrl + 'raktar/aktiv',
      args: { requestType: 'raktar-aktiv-list' },
      params: { id: id, cegadatid: cegadatId },
    }], fnDone);
  }

  static lockBizonylattomb(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.bizonylatUrl + 'biztomb/lezar',
      args: { requestType: 'biztomb-lezar' },
      params: { id: id }
    }], fnDone);
  }

  static createBizonylattomb(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.bizonylatUrl + 'biztomb',
      args: { requestType: 'bizonylat-create' },
      data: data,
    }], fnDone);
  }

  static updateBizonylattomb(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.bizonylatUrl + 'biztomb',
      args: { requestType: 'bizonylat-update' },
      data: data,
    }], fnDone);
  }

  static deleteBizonylattomb(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.bizonylatUrl + 'biztomb',
      args: { requestType: 'bizonylat-delete' },
      params: { id: id },
    }], fnDone);
  }

  static existsRaktar(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.bizonylatUrl + 'exists/raktar',
      args: { requestType: 'exists-raktar' },
    }], fnDone);
  }

  // BIZONYLATTÖMB END

  // CÉGADAT START
  
  static listAktivCegadat(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.cegadatUrl + 'search',
      params: {
        aktiv: true,
        resultclass: '30'
      },
      data: [id]
    }], fnDone);
  }
  
  // CÉGADAT END
}
